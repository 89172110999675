import React, { Component } from 'react'
import styles from './index.module.css'
import actions from 'store/actions/onBoard'
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';

class SetupInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: ''
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onFinish()
  }
  componentDidMount() {
    this.props.onComponentMount()
  }
  render() {
    return (
      <div className={styles.callContainer}>
        <br />
        <h2>Setup Conditional Call Forwarding on {this.props.data}</h2>
        {/* {this.props.data ? (<h2>Setup Conditional Call Forwarding on {this.props.data}</h2>):(<h2>Setup Conditional Call Forwarding on</h2>)} */}
        <p>In order to ensure your missed calls are being handled by us, setup call forwarding for the following conditions</p>
        <ol className={styles.list}>
          <li>Call unanswered <strong>(Recommended: 12 seconds or 8 rings)</strong></li>
          <li>Number busy</li>
          <li>Number unreachable</li>
        </ol>
        <br />
        <p>Not sure how to setup call forwarding? Use instructions below:</p>
        <ol>
          <li><a href="#" target="_blank" rel="noopener noreferrer">Call forwarding through a software  (e.g. RingCentral, Grasshopper, Google Voice)</a></li>
          <li><a href="#" target="_blank" rel="noopener noreferrer">Call forwarding through the carrier (AT&T, T-Mobile etc)</a></li>
        </ol>
        <br />
        <p><strong>Please note: This is the most crucial setup and ensure you test is thoroughly. </strong></p>
        <p><strong>Reach out to us for help if you are stuck.</strong></p>
        <div className={styles.nextBtn}>
          <Button variant="contained" color="primary" className="jr-btn jr-btn-lg" onClick={this.handleSubmit}>
            Finish
        </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ OnBoard }) => {
  return {
    data: OnBoard.data
  };
};

const mapDispachToProps = dispatch => {
  return {
    onFinish: () => dispatch({ type: actions.CREATE_TEAMBOT_COMPLETE }),
    onComponentMount: () => dispatch({ type: actions.GET_TEAM_BOT_DETAILS })
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(SetupInstructions);