// import blue from '@material-ui/core/colors/blue';
import deepOrange from '@material-ui/core/colors/deepOrange';

export default {
  palette: {
    primary: {
      light: '#FBD96A',
      main: '#fad045',
      dark: '#C8A637',
      contrastText: '#fff'
    },
    secondary: {
      light: deepOrange[300],
      main: deepOrange['A200'],
      dark: deepOrange[700],
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
};
