import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_STAGE === 'prod' || process.env.REACT_APP_STAGE === 'stag') {
  Sentry.init({dsn: "https://7dbf2cf6dcb849e5a4e490310c2f7807@o256655.ingest.sentry.io/5225728"});
}
const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  ReactDOM.render(
    <MainApp/>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(
      <MainApp/>,
      rootEl
    );
  });
}

render();
