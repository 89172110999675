import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import action from '../actions/conversation';
import { fetchConversationDetails, fetchContactMessages, sendMessage, toggeBotReply, fetchVoiceMessage, toggleConvStatus, editConversationName, sendNote, readConversation } from '../services/conversationServices';


export function* fetchConversationDetailsActionWatcher() {
    yield takeLatest(action.FETCH_CONVERSATION_DETAILS_REQUEST, function* (param) {
        const { payload } = param;
        try {
            const { data } = yield call(fetchConversationDetails, payload);
            if (data && data.code === 200) {
                console.log("data from conversatons", data);
                yield put({
                    type: action.FETCH_CONVERSATION_DETAILS_SUCCESS,
                    payload: data.payload,
                    hasNextConversation: data.payload.rows.length >= 10
                });
            }
            else {
                yield put({
                    type: action.FETCH_CONVERSATION_DETAILS_FAILURE,
                    msg: 'Some error occured.Please try again later'
                })
            }
        }
        catch (e) {
            console.log(e);
            yield put({
                type: action.FETCH_CONVERSATION_DETAILS_FAILURE,
                msg: 'Some error occured.Please try again later'
            })
        }
    });
}

export function* fetchContactMessagesActionWatcher() {
    yield takeLatest(action.FETCH_CONTACT_MESSAGE_REQUEST, function* (param) {
        const { payload } = param;
        try {
            payload.pageParam.read = false
            const { data } = yield call(fetchContactMessages, payload);
            if (data && data.code === 200) {

                console.log("contact msg", data);
                yield put({
                    type: action.FETCH_CONTACT_MESSAGE_SUCCESS,
                    payload: data.payload,
                    selectedUser: payload.selectedUser,
                    hasNext: data.payload.length >= 10
                });
            }
            else {
                yield put({
                    type: action.FETCH_CONTACT_MESSAGE_FAILURE,
                    msg: 'Some error occured.Please try again later'
                })
            }
        }
        catch (e) {
            console.log(e);
            yield put({
                type: action.FETCH_CONTACT_MESSAGE_FAILURE,
                msg: 'Some error occured.Please try again later'
            })
        }
    });
}

export function* sendMessageActionWatcher() {
    yield takeLatest(action.SEND_MESSAGE_REQUEST, function* (param) {
        const { payload } = param;
        try {
            const { data } = yield call(sendMessage, payload);
            if (data) {
                console.log("message sent success reply", data);
                yield put({
                    type: action.SEND_MESSAGE_SUCCESS,
                    payload: data.payload
                });
            }
            else {
                yield put({
                    type: action.SEND_MESSAGE_FAILURE,
                    msg: 'Some error occured.Please try again later'
                })
            }
        }
        catch (e) {
            console.log(e);
            yield put({
                type: action.SEND_MESSAGE_FAILURE,
                msg: 'Some error occured.Please try again later'
            })
        }
    });
}

export function* toggeBotReplyActionWatcher() {
    yield takeLatest(action.TOGGLE_BOT_REPLY_REQUEST, function* (param) {
        const { payload } = param;
        try {
            const { data } = yield call(toggeBotReply, payload);
            if (data) {
                console.log("toggle bot reply", data);
                yield put({
                    type: action.TOGGLE_BOT_REPLY_SUCCESS,
                    payload: data.payload
                });
            }
            else {
                yield put({
                    type: action.TOGGLE_BOT_REPLY_FAILURE,
                    msg: 'Some error occured.Please try again later'
                })
            }
        }
        catch (e) {
            console.log(e);
            yield put({
                type: action.TOGGLE_BOT_REPLY_FAILURE,
                msg: 'Some error occured.Please try again later'
            })
        }
    })
}

export function* fetchVoiceMessageActionWatcher() {
    yield takeLatest(action.FETCH_VOICE_MESSAGE_REQUEST, function* (param) {
        const { payload } = param;
        try {
            const { data } = yield call(fetchVoiceMessage, payload);
            if (data && data.code === 200) {
                console.log(data);
                yield put({
                    type: action.FETCH_VOICE_MESSAGE_SUCCESS,
                    payload: data.payload,
                    hasNextVoice: data.payload.rows.length >= 10
                });
            }
            else {
                yield put({
                    type: action.FETCH_VOICE_MESSAGE_FAILURE,
                    msg: 'Some error occured.Please try again later'
                })
            }
        }
        catch (e) {
            yield put({
                type: action.FETCH_VOICE_MESSAGE_FAILURE,
                msg: 'Some error occured.Please try again later'
            })
        }
    });
}

export function* saveConversationName() {
    yield takeLatest(action.SAVE_CONVERSATION_NAME_REQUEST, function* (param) {
        const { payload } = param
        try {
            yield call(editConversationName, payload)
            yield put({ type: action.SAVE_CONVERSATION_NAME_SUCCESS, payload: payload })
        }
        catch (err) {
            console.log(err)
        }
    })
}

export function* toogleConversationStatus() {
    yield takeLatest(action.UPDATE_CONVERSATION_STATUS_REQUEST, function* (param) {
        const { payload } = param;
        yield call(toggleConvStatus, payload);
        yield put({ type: action.UPDATE_CONVERSATION_STATUS_SUCCESS, payload: payload.conversationId })
    })
}

export function* sendNoteWatcher() {
    yield takeLatest("SEND_NOTE_REQUEST", function* (param) {
        const {payload} = param;
        const res = yield call(sendNote, payload);
        const resData = res.data.payload
        const note = {
            conversationId: resData.conversationId,
            createdAt: resData.createdAt,
            createdBy: resData.createdBy,
            id: resData.id,
            messageType: "log",
            msg: null,
            sender: null,
            type: resData.type,
            value: resData.value
        }
        yield put({type: "SEND_NOTE_SUCCESS", payload: note})
    })
}

export default function* rootSaga() {
    yield all([fork(fetchConversationDetailsActionWatcher),
    fork(fetchContactMessagesActionWatcher),
    fork(fetchVoiceMessageActionWatcher),
    fork(sendMessageActionWatcher),
    fork(toggeBotReplyActionWatcher),
    fork(toogleConversationStatus),
    fork(saveConversationName),
    fork(sendNoteWatcher)
    ]);
}