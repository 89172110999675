import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import OnBoard from './OnBoard'
import botReducer from './bot';
import conversationReducer from './conversations';


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  botReducer,
  conversationReducer,
  OnBoard
});
