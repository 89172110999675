import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import {deployBot} from '../../../store/services/botServices'
import { Alert } from 'reactstrap';

function BasicTable({props, data}) {

  const [errorMessage, setErrorMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')
  const [deployingBot, setDeployingBot] = React.useState('')

  const deployTeamBot = async (teamBotId) => {
    setDeployingBot(teamBotId)
    const deployedBot = await deployBot(teamBotId)
    if(deployedBot.status === 200){
      setSuccessMessage("Bot Deployed")
      setTimeout(() => {
        setSuccessMessage("")
        setDeployingBot('')
      }, 3000)
      console.log("deployed")
    }
    else{
      setErrorMessage("Could not deploy bot")
      setTimeout(() => {
        setErrorMessage("")
        setDeployingBot('')
      }, 3000)
      console.log("not deployed")
    }
  }

  return (
    <div className="table-responsive-material">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Display Name</TableCell>
            <TableCell align="right">Bot Number</TableCell>
            <TableCell align="right">Conversations</TableCell>
            <TableCell align="right">Nlp</TableCell>
            <TableCell align="right">Master Bot</TableCell>
            <TableCell align="right">Entities</TableCell>
            <TableCell align="right">Intents</TableCell>
            <TableCell align="right">Deploy to df</TableCell>
            <TableCell align="right">Messages</TableCell>
            <TableCell align="right">Tags</TableCell>
            {/* <TableCell align="right"></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(n => {
            return (
              <TableRow key={n.id}>
                <TableCell>{n.name}</TableCell>
                <TableCell align="right">{n.displayName}</TableCell>
                <TableCell align="right">{n.botNumber}</TableCell>
                <TableCell align="right"><Button onClick={() => props.history.push(`/app/conversations/${n.id}`)}>View</Button></TableCell>
                <TableCell style={n.nlp === "dialogflow" ? {color: "red"} : {color: "blue"}} align="right">{n.nlp}</TableCell>
                <TableCell style={n.botSetting.isMaster? {color: 'green'}: {color: 'orange'}} align="right">{n.botSetting.isMaster ? 'Yes': 'No'}</TableCell>
                <TableCell align="right"><Button onClick={() => props.history.push(`/app/teamBot/${n.id}/entities`)}>View</Button></TableCell>
                <TableCell align="right"><Button onClick={() => props.history.push(`/app/teamBot/${n.id}/intents`)}>View</Button></TableCell>
                <TableCell align="right"><Button disabled={n.nlp === "rasa"} onClick={() => deployTeamBot(n.id)}>Deploy</Button></TableCell>
                <TableCell align="right"><Button onClick={() => props.history.push(`/app/teamBot/${n.id}/messages`)}>View</Button></TableCell>
                <TableCell align="right"><Button onClick={() => props.history.push(`/app/teamBot/${n.id}/tags`)}>Add</Button></TableCell>
                <TableCell align="right">
                {errorMessage !== '' && deployingBot === n.id ? <Alert className="shadow-lg" color="secondary">
                    {errorMessage}
                  </Alert> : null}
                {successMessage !== '' && deployingBot === n.id ? <Alert className="shadow-lg" color="success">
                    {successMessage}
                  </Alert> : null}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}


export default BasicTable;