const dev = {

    api: {
        REGION: "YOUR_DEV_API_GATEWAY_REGION",
        // URL: "https://botapi.emitrr.com"
         URL: "http://localhost:3000",
         WEBHOOK_URL: "https://f191459d4936.ngrok.io",
         CURRENT_URL: "https://fee97e801bd2.ngrok.io",
         publicVapidKey: "BLVPM7YNSgaK2nev7o5Frems2RqAlF0gHSmaGbHPoXX6PeJlNRFhRR7tv_cZzZtjoCWC9jpg-2dZfrIMJhNDe4I",
         RINGCENTRAL_CLIENT_ID: "_cCz8s5KSqSJBRjl3Yu4Pw",
         RINGCENTRAL_CLIENT_SECRET: "kS_KZw4-TsmCg2dvIBjO3Akin8QO-NRwO4DxY0KkjzrA",
         RINGCENTRAL_SERVER: "sandbox"
    },

};

const prod = {

    api: {
        REGION: "YOUR_DEV_API_GATEWAY_REGION",
        URL: "https://botapi.emitrr.com",
        WEBHOOK_URL: "https://bot.emitrr.com",
        CURRENT_URL: "https://accounts.emitrr.com",
        publicVapidKey: "BDGtw_QbC0uoJzb8DdbVta8eCPJosPVn7yfgw-OUhfwm5c8YtukZh94QGmLRbQJolGyjWaHkCiXHQey8LsYzbuY",
        RINGCENTRAL_CLIENT_ID: "YThmM0SsS4GEr0Upvs_NoA",
        RINGCENTRAL_CLIENT_SECRET: "Jqk6104aRjar0cU6vtU2IQ78eLPsPORlu_KxKKXBLv7w",
        RINGCENTRAL_SERVER: "production"
    },

};

const staging = {

    api: {
        REGION: "YOUR_DEV_API_GATEWAY_REGION",
        URL: "https://testbotapi.emitrr.com",
        WEBHOOK_URL: "https://testbotwebhook.emitrr.com",
        CURRENT_URL: "https://testaccounts.emitrr.com",
        publicVapidKey: "BDGtw_QbC0uoJzb8DdbVta8eCPJosPVn7yfgw-OUhfwm5c8YtukZh94QGmLRbQJolGyjWaHkCiXHQey8LsYzbuY",
        RINGCENTRAL_CLIENT_ID: "_cCz8s5KSqSJBRjl3Yu4Pw",
        RINGCENTRAL_CLIENT_SECRET: "kS_KZw4-TsmCg2dvIBjO3Akin8QO-NRwO4DxY0KkjzrA",
        RINGCENTRAL_SERVER: "sandbox"
    },

};


const config = process.env.REACT_APP_STAGE === 'prod' ? prod : (process.env.REACT_APP_STAGE === 'stag' ? staging : dev);

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};