import axios from '../../util/axios';
import { createUrl } from '../../util/common';
import * as urls from 'util/urlsConst';

export function fetchConversationDetails(param) {
    const {teamBotId, pageParam={},status, responded, tags} = param;
    console.log(param)
    let url = createUrl(urls.CONVERSATION + urls.FETCH_CONVERSATION) + `/${teamBotId}`;
    if(param.text){
        const { pageParam, text} = param;
        pageParam.search = text
    } 
    pageParam.status = status
    pageParam.responded = responded
    pageParam.tags = tags
    return axios.request({
        method: 'get',
        url: url,
        headers: {
            'Content-Type': 'application/json'
        },
        params: pageParam
    })
}

export function toggleConvStatus(param) {
    const {conversationId, status} = param;
    let url = createUrl(urls.CONVERSATION + urls.TOGGLE_STATUS) + `/${conversationId}`;
    const params = {status: status}
    return axios.request({
        method: 'post',
        url: url,
        headers: {
            'Content-Type': 'application/json'
        },
        params: params
    })
}

export function fetchContactMessages(param) {
    return axios.request({
        method: 'get',
        url: createUrl(urls.CONVERSATION + urls.MESSAGES) + `/${param.id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        params: param.pageParam
    })
}

export function sendMessage(param) {
    const {teamBotId, conversationId,  msgParam={}} = param;
    return axios.request({
        method: 'post',
        url: createUrl(urls.CONVERSATION + urls.SEND_MSG + urls.TEAMBOT) + `/${teamBotId}${urls.CONVERSATION}/${conversationId}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: msgParam
    })
}

export function toggeBotReply(param) {
    const {conversationId,  msgParam={}} = param;
    return axios.request({
        method: 'put',
        url: createUrl(urls.CONVERSATION + urls.SETTING) + `/${conversationId}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: msgParam
    })
}

export function fetchVoiceMessage(param) {
    const {teamBotId, pageParam={}} = param;
    return axios.request({
        method: 'get',
        url: createUrl(urls.CONVERSATION + urls.VOICEMAIL) + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json'
        },
        params: pageParam
    })
}

export function editConversationName(param) {
    const access_token = localStorage.getItem('token')
    const {id,firstName, lastName} = param
    return axios.request({
        method: 'post',
        url: createUrl(urls.CONVERSATION + urls.EDIT_NAME) + `/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
        data: {firstName: firstName, lastName: lastName}
    })
}

export function sendNote(param) {
    const access_token = localStorage.getItem('token')
    const {teamBotId, data} = param
    return axios.request({
        method: 'post',
        url: createUrl(urls.CONVERSATION + urls.CREATE_NOTE) + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
        data: data
    })
}