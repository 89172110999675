import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import {push} from 'connected-react-router';
import onBoardActions from '../actions/onBoard'
import {getTeam, createTeamAndTeambot} from '../services/onBoardServices'

function* onBoardAsync({payload}) {
  yield put({type: onBoardActions.SHOW_ON_BOARD_LOADER})
  const params = {templateId: payload, botId: 1}
  const res = yield call(createTeamAndTeambot, params)
  yield put({ type: onBoardActions.CREATE_TEAMBOT_SUCCESS, value: res.data.payload.botNumber });
  yield put({ type: onBoardActions.HIDE_ON_BOARD_LOADER})
}

export function* watchOnBoard() {
  yield takeLatest(onBoardActions.CREATE_TEAMBOT_REQUEST, onBoardAsync);
}

function* onBoardingAsync() {
  const access_token = localStorage.getItem('token')
  const res = yield call(getTeam);
  if(res.data.payload.length === 0 && access_token !== null){
    yield put(push('/onBoard'))
  }
  else if(res.data.payload.length !== 0 && access_token !== null){
    yield put(push('/app/conversations'))
  }
}

export function* onBoarding() {
  yield takeLatest(onBoardActions.CHECK_TEAMBOT_EXISTS, onBoardingAsync)
}

function* onBoardingFinishAsync() {
  yield put(push('/app/conversations'))
}

export function* onBoardingFinish() {
  yield takeLatest(onBoardActions.CREATE_TEAMBOT_COMPLETE, onBoardingFinishAsync)
}

export default function* rootSaga() {
  yield all([fork(watchOnBoard),
      fork(onBoarding),
      fork(onBoardingFinish)]);
}