import actions from '../actions/onBoard'

const initialState = {
    data: "",
    loading: false
  };
  
  const OnBoard = (state = initialState, action) => {
    const newState = { ...state };
  
    switch (action.type) {
      case actions.CREATE_TEAMBOT_SUCCESS:
        newState.data = action.value;
        break;
      case actions.GET_TEAM_BOT_DETAILS:
        return newState;
      case actions.SHOW_ON_BOARD_LOADER:
        return {...newState, loading:true}
      case actions.HIDE_ON_BOARD_LOADER:
        return {...newState, loading:false}
      default:
        return newState;

    }
    return newState;
  };
  
  export default OnBoard;
  