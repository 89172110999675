import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    hideMessage,
    resendEmail,
} from 'store/actions/Auth';
import Button from '@material-ui/core/Button';

export default function EmailVerification(props){

    const dispatch = useDispatch();
    const { showMessage, authUser } = useSelector(({ auth }) => auth);
    const token = localStorage.getItem('signup_token');


    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
    }, [showMessage, authUser, props.history, dispatch]);


    return (
        <div
            className="app-login-container  d-flex flex-column justify-content-center align-items-center">
            {/* <div className="app-login-main-content"> */}
            <div>
            <h1 className=" email-verify-main-content fw-regular title">
        We've sent you a confirmation email.</h1>
        </div>
        <div className= "text-right">
        <h2> Please check your inbox/spam for an email from us.</h2>
        </div>
       {token && <div> 
        <Button onClick ={() => {
            dispatch(resendEmail());
        }} variant="contained" size="medium" color="primary">
            Resend Link
        </Button>
        </div>}
            </div>
        // </div>
    );

}

// export default emailVerification;
