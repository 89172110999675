
const conversationActions = {
    FETCH_CONVERSATION_DETAILS_REQUEST: 'FETCH_CONVERSATION_DETAILS_REQUEST',
    FETCH_CONVERSATION_DETAILS_SUCCESS: 'FETCH_CONVERSATION_DETAILS_SUCCESS',
    FETCH_CONVERSATION_DETAILS_FAILURE: 'FETCH_CONVERSATION_DETAILS_FAILURE',
    CLEAR_CONVERSATION_DETAILS: 'CLEAR_CONVERSATION_DETAILS',
    fetchConversationDetailsWatcher: (param) => ({ type: conversationActions.FETCH_CONVERSATION_DETAILS_REQUEST, payload: param }),

    FETCH_CONTACT_MESSAGE_REQUEST: 'FETCH_CONTACT_MESSAGE_REQUEST',
    FETCH_CONTACT_MESSAGE_SUCCESS: 'FETCH_CONTACT_MESSAGE_SUCCESS',
    FETCH_CONTACT_MESSAGE_FAILURE: 'FETCH_CONTACT_MESSAGE_FAILURE',
    fetchContactMessagesWatcher: (param) => ({ type: conversationActions.FETCH_CONTACT_MESSAGE_REQUEST, payload: param }),

    SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
    SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',
    sendMessageWatcher: (param) => ({ type: conversationActions.SEND_MESSAGE_REQUEST, payload: param }),

    TOGGLE_BOT_REPLY_REQUEST: 'TOGGLE_BOT_REPLY_REQUEST',
    TOGGLE_BOT_REPLY_SUCCESS: 'TOGGLE_BOT_REPLY_SUCCESS',
    TOGGLE_BOT_REPLY_FAILURE: 'TOGGLE_BOT_REPLY_FAILURE',
    toggleBotReplyWatcher: (param) => ({ type: conversationActions.TOGGLE_BOT_REPLY_REQUEST, payload: param }),

    FETCH_VOICE_MESSAGE_REQUEST: 'FETCH_VOICE_MESSAGE_REQUEST',
    FETCH_VOICE_MESSAGE_SUCCESS: 'FETCH_VOICE_MESSAGE_SUCCESS',
    FETCH_VOICE_MESSAGE_FAILURE: 'FETCH_VOICE_MESSAGE_FAILURE',
    fetchVoiceMessageWatcher: (param) => ({ type: conversationActions.FETCH_VOICE_MESSAGE_REQUEST, payload: param }),

    SET_TAGS: 'SET_TAGS',
    PAGE_NUMBER_RESET: 'PAGE_NUMBER_RESET',
    SHOW_RESPONDED_CONVERSATIONS: 'SHOW_RESPONDED_CONVERSATIONS',
    SET_SELECTED_TAGS: 'SET_SELECTED_TAGS',
    CHANGE_CONVERSATION_TYPE: 'CHANGE_CONVERSATION_TYPE',
    FETCH_REAL_TIME_MESSAGES: 'FETCH_REAL_TIME_MESSAGES',
    FETCH_REAL_TIME_CONVERSATION_DETAILS: 'FETCH_REAL_TIME_CONVERSATION_DETAILS',
    SEARCH_TEXT_CHANGE: 'SEARCH_TEXT_CHANGE',
    UPDATE_CONVERSATION_STATUS_REQUEST: 'UPDATE_CONVERSATION_STATUS_REQUEST',
    UPDATE_CONVERSATION_STATUS_SUCCESS: 'UPDATE_CONVERSATION_STATUS_SUCCESS',
    TOGGLE_OPENED_CONVERSATION: 'TOGGLE_OPENED_CONVERSATION',
    SCROLLBAR_POSITION: 'SCROLLBAR_POSITION',
    SAVE_CONVERSATION_NAME_REQUEST: 'SAVE_CONVERSATION_NAME_REQUEST',
    SAVE_CONVERSATION_NAME_SUCCESS: 'SAVE_CONVERSATION_NAME_SUCCESS'
}
export default conversationActions;


