import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import {push} from 'connected-react-router';
import {
    auth,
    facebookAuthProvider,
    githubAuthProvider,
    googleAuthProvider,
    twitterAuthProvider
} from "../../firebase/firebase";
import {
    SIGNIN_FACEBOOK_USER,
    SIGNIN_GITHUB_USER,
    SIGNIN_GOOGLE_USER,
    SIGNIN_TWITTER_USER,
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER,
    RESEND_MAIL
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "store/actions/Auth";
import {
    userFacebookSignInSuccess,
    userGithubSignInSuccess,
    userGoogleSignInSuccess,
    userTwitterSignInSuccess
} from "../actions/Auth";
import axios from '../../util/axios';
import {createUrl} from '../../util/common';
import * as urls from '../../util/urlsConst';
import actions from '../actions/bot';


const createUserWithEmailPasswordRequest = async (param) => {
    return axios.request({
        method: 'post',
        url: createUrl(urls.USER + urls.REGISTER),
        headers: {
          'Content-Type': 'application/json',
        },
        data: param
    })
}

function* createUserWithEmailPassword({payload}) {
    // const {name, email, password} = payload;
    try {
        const signUpUser = yield call(createUserWithEmailPasswordRequest, payload);
        if (signUpUser.status === 200) {
            const {data: {payload}} = signUpUser;
            // yield put(showAuthMessage('User successfully created'));
        
            // localStorage.setItem('user_id', payload.id);
            localStorage.setItem('signup_token', payload.token)
            // yield put(userSignUpSuccess(payload.id));
            if(!payload.isActive){
                yield put(push('/email-verify'));
            }
        }else {
            console.log("there is some problem")
        }
    } catch (error) {
        let msg = 'Something went wrong. Please try again.';
        if(error && error.message) msg = error.message;
        if(error && error.data) msg = error.data.message;
        yield put(showAuthMessage(msg));
    }
}

export function* createUserAccount() {
    yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

//######## RESEND MAIL #######################################

const resendUserMail = (token) => {
    return axios.request({
        method: 'get',
        url: createUrl(urls.USER + urls.RESEND),
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export function * resendUserMailActionEffect() {
    const token = localStorage.getItem('signup_token');
    try {
        const resendMail = yield call(resendUserMail, token);
        yield put(showAuthMessage('Mail sent successfully'));

    }catch(error) {
        let msg = 'Something went wrong. Please try again.';
        if(error && error.message) msg = error.message;
        if(error && error.data) msg = error.data.message;
        yield put(showAuthMessage(msg));
    }
}

export function * resendUserMailActionWatcher() {
    yield takeLatest(RESEND_MAIL, resendUserMailActionEffect);
}


const signInUserWithEmailPasswordRequest = (email, password) => {

    const param ={
        email,
        password
    }
    return axios.request({
        method: 'post',
        url: createUrl(urls.USER + urls.LOGIN),
        headers: {
          'Content-Type': 'application/json',
        },
        data: param
      })
    }

    function* signInUserWithEmailPassword({payload}) {
        const {email, password} = payload;
        try {
            const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
            if (signInUser.status === 200) {
                const {data: {payload}} = signInUser;
                const {fetchBotDetailsWatcher} =actions
                // yield put(showAuthMessage('User successfully created'));
            
                localStorage.setItem('user_id', payload.id);
                localStorage.setItem('token', payload.token)
                yield put(userSignUpSuccess(payload.id));
                yield call(fetchBotDetailsWatcher());          
            }else {
                console.log("there is some problem")
            }
        } catch (error) {
            let msg = 'Something went wrong. Please try again.';
            if(error && error.status === 403) yield put(push('/email-verify'));
            if(error && error.message) msg = error.message;
            if(error && error.data) msg = error.data.message;
            yield put(showAuthMessage(msg));
        }
    }

    export function* signInUser() {
        yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
    }

const signOutRequest = async () =>
    await  auth.signOut()
        .then(authUser => authUser)
        .catch(error => error);


const signInUserWithGoogleRequest = async () =>
    await  auth.signInWithPopup(googleAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithFacebookRequest = async () =>
    await  auth.signInWithPopup(facebookAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithGithubRequest = async () =>
    await  auth.signInWithPopup(githubAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithTwitterRequest = async () =>
    await  auth.signInWithPopup(twitterAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);



function* signInUserWithGoogle() {
    try {
        const signUpUser = yield call(signInUserWithGoogleRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGoogleSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithFacebook() {
    try {
        const signUpUser = yield call(signInUserWithFacebookRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userFacebookSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithGithub() {
    try {
        const signUpUser = yield call(signInUserWithGithubRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGithubSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithTwitter() {
    try {
        const signUpUser = yield call(signInUserWithTwitterRequest);
        if (signUpUser.message) {
            if (signUpUser.message.length > 100) {
                yield put(showAuthMessage('Your request has been canceled.'));
            } else {
                yield put(showAuthMessage(signUpUser.message));
            }
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userTwitterSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}



function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        if (signOutUser === undefined) {
            localStorage.removeItem('user_id');
            localStorage.removeItem('token')
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* signInWithGoogle() {
    yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
    yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
    yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
    yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}



export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([fork(signInUser),
        fork(createUserAccount),
        fork(signInWithGoogle),
        fork(signInWithFacebook),
        fork(signInWithTwitter),
        fork(signInWithGithub),
        fork(resendUserMailActionWatcher),
        fork(signOutUser)]);
}