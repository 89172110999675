import actions from '../actions/bot';

const initState = {
    isBotLoading: false
}

export default function botReducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCH_BOT_DETAILS_REQUEST:
            return {
                ...state,
                isBotLoading: true
            };
        case actions.FETCH_BOT_DETAILS_SUCCESS:
            return {
                ...state,
                botDetails: action.payload,
                teamBotId: action.teamBotId
            };
        case actions.FETCH_BOT_DETAILS_FAILURE:
            return {
                ...state,
                errorMsg: action.msg
            };

        case "CLEAR_TEAMBOT_DETAILS":
                return {
                    isBotLoading: false
                }

        default:
            return state;

    }
}