const onBoardActions = {
    CREATE_TEAMBOT_REQUEST: "CREATE_TEAMBOT_REQUEST",
    CREATE_TEAMBOT_SUCCESS: "CREATE_TEAMBOT_SUCCESS",
    CHECK_TEAMBOT_EXISTS: "CHECK_TEAMBOT_EXISTS",
    CREATE_TEAMBOT_COMPLETE: "CREATE_TEAMBOT_COMPLETE",
    GET_TEAM_BOT_DETAILS: "GET_TEAM_BOT_DETAILS",
    SHOW_ON_BOARD_LOADER: "SHOW_ON_BOARD_LOADER",
    HIDE_ON_BOARD_LOADER: "HIDE_ON_BOARD_LOADER"
}

export default onBoardActions;