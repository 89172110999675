import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';
import ExtraPages from './extraPages'
import DashBoard from "./TeamBots";
// import Contact from './contact/basic';



const Routes = ({ match }) =>
  <Switch>

     <Route exact path={`${match.url}/sample-page`}
      component={asyncComponent(() => import('./SamplePage'))} /> 

    <Route exact path={`${match.url}/reset-password`}
      component={asyncComponent(() => import('./resetPassword'))} /> 
      <Route exact path={`${match.url}/conversations/:teamBotId`}
      component={asyncComponent(() => import('./chatPanel/basic'))} />
      
    {/* <Route path={`${match.url}/conversations`}
      component={asyncComponent(() => import('./chatPanel/basic'))} />  */}

      <Route path={`${match.url}/teamBot/:teamBotId/intent/:intentId`} component={asyncComponent(() => import('./Phrases'))}  />
      <Route path={`${match.url}/teamBot/:teamBotId/intents`} component={asyncComponent(() => import('./Intents'))}  />
      <Route path={`${match.url}/teamBot/:teamBotId/entity/:entityId`} component={asyncComponent(() => import('./Entity'))}  />
      <Route path={`${match.url}/teamBot/:teamBotId/entities`} component={asyncComponent(() => import('./Entities'))}  />
      <Route path={`${match.url}/teamBot/:teamBotId/messages`} component={asyncComponent(() => import('./Messages'))}  />
      <Route path={`${match.url}/teamBot/:teamBotId/tags`} component={asyncComponent(() => import('./Tags'))}  />

      <Route path={`${match.url}/teamBots`} component={asyncComponent(() => import('./TeamBots'))}  />


    <Route path={`${match.url}/extra-pages`} component={ExtraPages} />
    <Route component={asyncComponent(() => import("./extraPages/routes/404"))} />
    
  </Switch>;


export default withRouter(Routes);

