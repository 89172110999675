import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import action from '../actions/bot';
import {fetchBotDetails} from '../services/botServices';
import onBoardActions from '../actions/onBoard'
export function* fetchBotDetailsActionWatcher() {
    yield takeLatest(action.FETCH_BOT_DETAILS_REQUEST, function* (params) {
        try {
            const { data } = yield call(fetchBotDetails, params.payload);
            if (data && data.code === 200) {
                console.log('fetchBotDetails',data);
                if(data.payload.length === 0){
                    yield put({type: onBoardActions.CHECK_TEAMBOT_EXISTS}) 
                }
                
                const { team = {} } = data.payload[0];
                const teamBotId = team.teamBots[0].id;
            yield put({
                type: action.FETCH_BOT_DETAILS_SUCCESS,
                payload: data.payload,
                teamBotId: teamBotId
            });

                
            }
            else {
                yield put({
                    type: action.FETCH_BOT_DETAILS_FAILURE,
                    msg: 'Some error occured.Please try again later'
                })
            }
        }
        catch (e) {
            console.log(e);
            yield put({
                type: action.FETCH_BOT_DETAILS_FAILURE,
                msg: 'Some error occured.Please try again later'
            })
        }
    });
}

export default function* rootSaga() {
    yield all([fork(fetchBotDetailsActionWatcher)]);
}

