import React, { useState, useEffect } from 'react'
import styles from './index.module.css'
import { connect } from "react-redux";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import actions from 'store/actions/onBoard'
import axios from 'axios';
import Button from '@material-ui/core/Button';
import * as urls from 'util/urlsConst';
import { createUrl } from 'util/common';
import CircularProgress from '@material-ui/core/CircularProgress';

function CreateTeam(props) {

  const [template, setTemplate] = useState({id: 1, name: 'Default'});

  const [templates, setTemplates] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.ongetStarted(template.id)
  }

  const handleTemplateChange = (e) => {
    setTemplate(e.target.value)
  }

  async function fetchTemplates() {
    axios.request({
      method: 'get',
      url: createUrl(urls.TEMPLATE + urls.GET_TEMPLATES),
    })
      .then(res => {
        console.log('res', res.data.payload[0]);
        setTemplate(res.data.payload[0]);
        setTemplates(res.data.payload);
      })
      .catch(err => {
        console.log(err)
      })
  }


  useEffect(() => {
    if (props.data !== "" && props.loading === false) {
      props.handleSetScreen(3)
    }
    if (templates.length === 0) {
      fetchTemplates();
    }
  }, [props, templates.length])

  return (
    <div className={styles.teamContainer}>
      {((props.loading === false) && (templates.length > 0)) ? (<div>
        <label className={styles.industryLabel}></label>
        <div>
          <FormControl className="w-50 mb-2">
            <InputLabel id="demo-simple-select-label">Choose Industry</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={template}
              onChange={(e) => handleTemplateChange(e)}
              displayEmpty
            // className={styles.selectEmpty}
            >
              {templates && templates.map(template => {
                return <MenuItem key={template.id} value={template}>{template.subType}</MenuItem>
              })}
            </Select>
          </FormControl>
          {/* <div className={styles.btnDiv}>
            <button className={styles.btnNext} onClick={handleSubmit}>Get Started</button>
          </div> */}
          <div className={styles.nextBtn}>
            <Button variant="contained" color="primary" className="jr-btn jr-btn-lg" onClick={handleSubmit}>
              Next
            </Button>
          </div>
        </div>
      </div>
      ) : <div className={styles.progressBar}>
      <CircularProgress/>
    </div>}
    </div>
  )
}

const mapStateToProps = ({ OnBoard }) => {
  return {
    data: OnBoard.data,
    loading: OnBoard.loading
  };
};

const mapDispachToProps = dispatch => {
  return {
    ongetStarted: (template) => dispatch({ type: actions.CREATE_TEAMBOT_REQUEST, payload: template }),
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(CreateTeam);