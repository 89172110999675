import React from 'react'
import Features from './features.js';
import Slider from "react-slick";
import Button from '@material-ui/core/Button';
import styles from './index.module.css'
import conversation from 'assets/images/onBoard/conversation.png'
import metrics from 'assets/images/onBoard/metrics.png'
import voicemail from 'assets/images/onBoard/voicemail.png'

const features = [
  {
    name: 'Conversations',
    destination: '',
    description: 'You can read all the customer conversations and even respond to them wherever needed.',
    image: conversation
  },
  {
    name: 'Voicemails',
    destination: '',
    description: 'Analyse voicemails and create custom voicemail greetings',
    image: voicemail
  },
  {
    name: 'Metrics',
    destination: '',
    description: 'Make better business decisions by tracking number of missed calls and calls getting handled.',
    image: metrics
  }
];

function AboutEmitrr(props) {

  const options = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <>
      <div className="animated slideInUpTiny animation-duration-3">
        <Slider className="slick-slider-cr" {...options}>
          <div className="slick-slide-item">
            <Features feature={features[0]} />
          </div>
          <div className="slick-slide-item">
            <Features feature={features[1]} />
          </div>
          <div className="slick-slide-item">
            <Features feature={features[2]} />
          </div>

        </Slider>
        <div className={styles.nextBtn}>
          <Button variant="contained" color="primary" className="jr-btn jr-btn-lg" onClick={() => props.handleSetScreen(2)}>
            Next
        </Button>
        </div>
      </div>
    </>
  )
}

export default AboutEmitrr;