import React from 'react';

const Header = ({title}) => {

  return (
    <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
      <h2 className="title mb-3 mb-sm-0">{title}</h2>
    </div>
  )
};

export default Header;

