import React, { useState, useEffect } from 'react'
import BasicTable from './BasicTable'
import {getAllBots} from '../../../store/services/botServices'
import Header from './Header'
import { useDispatch } from 'react-redux'

function TeamBots(props) {
    const dispatch = useDispatch();
    const [teamBots,setTeamBots] = useState([])
    useEffect(() => {
        getAllBots()
            .then(res => {
                setTeamBots(res.data.payload)
            })
            .catch(err => console.log(err))
        dispatch({type: "CLEAR_TEAMBOT_DETAILS"})
        dispatch({type: "CLEAR_DETAILS"})
        },[])

    return (
        <div>
            <Header title="TeamBots"/>
            <BasicTable props={props} data={teamBots}/>
        </div>
    )
}

export default TeamBots