import actions from "../actions/conversation";
import { isEmpty } from 'util/common';

const initState = {
    conversationDetails: {
        count: 0,
        rows: [],
        status: true
    },
    isConversationLoading: true,
    isContactMessageLoading: false,
    isMessageSending: false,
    switchLoader: false,
    isVoiceMessageLoading: false,
    isNewContactSelected: false,
    isConversationLoadingFirstTime: true,
    search: '',
    scrollTop: 0,
    page: 1,
    conversationType: "all",
    responded: undefined,
    tags: [],
    selectedTags: [],
}

export default function conversationReducer(state = initState, action) {

    switch (action.type) {
        case actions.CLEAR_CONVERSATION_DETAILS:
            const emptyConvDetails = {
                count: 0,
                rows: [],
                status: true
            }
            return {
                ...state,
                conversationDetails: emptyConvDetails,
            }
        case actions.FETCH_CONVERSATION_DETAILS_REQUEST:
            return {
                ...state,
                isConversationLoading: true,
                isLoadingFirstTime: isEmpty(state.conversationDetails)
            };
        case actions.FETCH_CONVERSATION_DETAILS_SUCCESS:
            let { conversationDetails: details } = state;
            if (details.rows.length !== 0) {
                details.rows = [...details.rows, ...action.payload.rows]
            }
            else {
                details = action.payload
                details.status = state.conversationDetails.status
            }
            return {
                ...state,
                conversationDetails: details,
                isConversationLoading: false,
                isConversationLoadingFirstTime: false,
                hasNextConversation: action.hasNextConversation
            };
        case actions.FETCH_CONVERSATION_DETAILS_FAILURE:
            return {
                isConversationLoading: false,
                errorMsg: action.msg
            };


        case actions.FETCH_CONTACT_MESSAGE_REQUEST:

            const flag = state.selectedUser && action.payload.selectedUser.phone === state.selectedUser.phone
            return {
                ...state,
                isContactMessageLoading: true,
                botReply: action.payload.selectedUser.botReply,
                conversation: !flag ? [] : state.conversation,
                isNewContactSelected: !flag
            };
        case actions.FETCH_CONTACT_MESSAGE_SUCCESS:
            let { conversation: conv } = state;
            if (conv) conv = [...conv, ...action.payload]
            else conv = action.payload
            return {
                ...state,
                isContactMessageLoading: false,
                conversation: conv,
                selectedUser: action.selectedUser,
                selectedSectionId: action.selectedUser.id,
                hasNext: action.hasNext,
                isNewContactSelected: false
            };
        case actions.FETCH_CONTACT_MESSAGE_FAILURE:
            return {
                ...state,
                isContactMessageLoading: false,
                errorMsg: action.msg
            };
        case actions.FETCH_REAL_TIME_MESSAGES:
            let { conversation: con, selectedUser, botReply, conversationDetails: conveDetails } = state;
            let newConvArr;
            if (con.some(message => message.id === action.payload.id)) {
                return {
                    ...state,
                    newConvArr
                }
            }
            let reply;
            let newDetails;
            let newCDetails;
            if (action.payload.conversationId === selectedUser.id) {
                if (action.payload.type === "miss_call") {
                    reply = true;
                    newDetails = conveDetails.rows.map(obj => {
                        if (obj.id === selectedUser.id) {
                            obj.botReply = true;
                        }
                        return obj;
                    })
                    newCDetails = {
                        count: newDetails.length,
                        rows: newDetails,
                        status: state.conversationDetails.status
                    }
                }
                else {
                    reply = botReply
                    newCDetails = {
                        count: conveDetails.count,
                        rows: conveDetails.rows,
                        status: state.conversationDetails.status
                    }
                }
                newConvArr = [action.payload, ...con]
            }
            else {
                newConvArr = [...con]
                newCDetails = {
                    count: conveDetails.count,
                    rows: conveDetails.rows,
                    status: state.conversationDetails.status
                }
            }
            return {
                ...state,
                conversation: newConvArr,
                botReply: reply,
                conversationDetails: newCDetails
            }

        case actions.FETCH_REAL_TIME_CONVERSATION_DETAILS:
            if (state.conversationDetails.status === false) {
                return {
                    ...state
                }
            }
            let { conversationDetails: convDetails } = state;
            let filteredConvDetails = convDetails.rows.filter(function (conversation) {
                return conversation.id !== action.payload.conversationId;
            });
            const userConvDetails = {
                id: action.payload.conversationId,
                phone: action.payload.phone,
                source: action.payload.source,
                teamBotId: action.payload.teamBotId,
                botReply: action.payload.botReply,
                isActive: action.payload.isActive,
                createdAt: action.payload.createdAt,
                updatedAt: action.payload.updatedAt,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                unRead: action.payload.unRead
            }
            const newConvDetails = [userConvDetails, ...filteredConvDetails];
            const count = newConvDetails.length;
            const newConvObj = {
                count: count,
                rows: newConvDetails,
                status: state.conversationDetails.status
            }
            return {
                ...state,
                conversationDetails: newConvObj,
            }

        case actions.SEND_MESSAGE_REQUEST:
            return {
                ...state,
                isMessageSending: true,
            };
        case actions.SEND_MESSAGE_SUCCESS:
            let { conversation: conver } = state;
            conver.push(action.payload);
            return {
                ...state,
                isMessageSending: false,
                sentMessage: action.payload,
            };
        case actions.SEND_MESSAGE_FAILURE:
            return {
                ...state,
                isMessageSending: false,
                errormsg: action.msg
            }

        case actions.TOGGLE_BOT_REPLY_REQUEST:
            return {
                ...state,
                switchLoader: true,
            };
        case actions.TOGGLE_BOT_REPLY_SUCCESS:
            let { conversationDetails: conDetails } = state;
            let newConveDetails = conDetails.rows.map(obj => {
                if (obj.id === state.selectedUser.id) {
                    obj.botReply = !obj.botReply;
                }
                return obj;
            })
            return {
                ...state,
                switchLoader: false,
                botReply: !state.botReply,
                toggleMessage: action.payload,
                conversationDetails: { count: newConveDetails.length, rows: newConveDetails, status: state.conversationDetails.status }
            };
        case actions.TOGGLE_BOT_REPLY_FAILURE:
            return {
                ...state,
                switchLoader: false,
                errormsg: action.msg
            };

        case actions.FETCH_VOICE_MESSAGE_REQUEST:
            return {
                ...state,
                isVoiceMessageLoading: true,
            };
        case actions.FETCH_VOICE_MESSAGE_SUCCESS:
            let { voiceMailDetails: voice } = state;
            if (voice)
                voice.rows = [...voice.rows, ...action.payload.rows]
            else
                voice = action.payload
            return {
                ...state,
                isVoiceMessageLoading: false,
                voiceMailDetails: voice,
                hasNextVoice: action.hasNextVoice
            };
        case actions.FETCH_VOICE_MESSAGE_FAILURE:
            return {
                ...state,
                isVoiceMessageLoading: false,
                errormsg: action.msg
            }

        default:
            return state;
        case actions.SEARCH_TEXT_CHANGE:
            let search = action.payload
            return {
                ...state,
                search
            }
        case actions.SCROLLBAR_POSITION:
            // console.log(action.payload)
            let scrollTopValue = action.payload
            return {
                ...state,
                scrollTop: scrollTopValue
            }

        case actions.TOGGLE_OPENED_CONVERSATION:
            let { conversationDetails: cDetails } = state;
            cDetails.status = action.payload
            return {
                ...state,
                conversationDetails: cDetails,
                scrollTop: 0,
                selectedUser: {},
                selectedSectionId: ''
            }

        case actions.UPDATE_CONVERSATION_STATUS_SUCCESS:
            let { conversationDetails: coDetails } = state;
            let filterConvDetails = coDetails.rows.filter(function (conversation) {
                return conversation.id !== action.payload;
            });
            let newConversationsDetailsObj = {
                count: filterConvDetails.length,
                rows: filterConvDetails,
                status: state.conversationDetails.status
            }
            return {
                ...state,
                conversationDetails: newConversationsDetailsObj,
                selectedSectionId: '',
                selectedUser: {}
            }
        case actions.SAVE_CONVERSATION_NAME_SUCCESS:
            let { conversationDetails: conversDetails } = state;
            let newConverDetailsRows = conversDetails.rows.map(obj => {
                if (obj.id === action.payload.id) {
                    obj.firstName = action.payload.firstName;
                    obj.lastName = action.payload.lastName;
                }
                return obj;
            })
            let newConversDetails = {
                rows: newConverDetailsRows,
                count: newConverDetailsRows.length,
                status: state.conversationDetails.status
            }
            return {
                ...state,
                conversationDetails: newConversDetails
            }

    case "SEND_NOTE_SUCCESS":
        let {conversation:messages} = state;
        const newMessages = [action.payload, ...messages]
        return {
            ...state,
            conversation: newMessages
        }

    case "CHANGE_PAGE_NUMBER":
        let {page: pageNum} = state
        pageNum = pageNum + 1
        return {
            ...state,
            page:pageNum
        }

    case "CONVERSATION_READ_SUCCESS":
        let { conversationDetails: converseDetails } = state;
        let newConverseDetailsRows = converseDetails.rows.map(obj => {
            // if (obj.id === action.payload.id) {
            //     obj.unRead = 0
            // }
            return obj;
        })
        let newConverseDetails = {
            rows: newConverseDetailsRows,
            count: newConverseDetailsRows.length,
            status: state.conversationDetails.status
        }
        return {
            ...state,
            conversationDetails: newConverseDetails
        }
    case "CLEAR_DETAILS":
            return {
                conversationDetails: {
                    count: 0,
                    rows: [],
                    status: true
                },
                isConversationLoading: true,
                isContactMessageLoading: false,
                isMessageSending: false,
                switchLoader: false,
                isVoiceMessageLoading: false,
                isNewContactSelected: false,
                isConversationLoadingFirstTime: true,
                search: '',
                scrollTop: 0,
                page: 1
            }
    
            case actions.SHOW_RESPONDED_CONVERSATIONS:
                return {
                    ...state,
                    responded: action.payload
                }
        
            case actions.SET_TAGS:
                return {
                    ...state,
                    tags: action.payload
                }
        
            case actions.SET_SELECTED_TAGS:
                return {
                    ...state,
                    selectedTags: action.payload
                }
                case actions.UPDATE_FOLLOWUP_STATUS:
                return {
                  ...state,
                  conversationDetails: {
                    ...state.conversationDetails,
                    rows: state.conversationDetails.rows.map((row) => {
                      if (row.id != action.payload) {
                        return row;
                      }
                      let updatedRow = { ...row };
                      updatedRow.hasActiveFollowups = false;
                      return updatedRow;
                    }),
                  },
                };

                case actions.CHANGE_CONVERSATION_TYPE:
        console.log(action.payload)
        return {
            ...state,
            conversationType: action.payload
        }

        case actions.PAGE_NUMBER_RESET:
            return {
                ...state,
                page:1
            }
    }
}