import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import onBoardSagas from './OnBoard'
import botSagas from './bot';
import conversationSagas from './conversation';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        botSagas(),
        conversationSagas(),
        onBoardSagas()
    ]);
}
