import React, { useEffect } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import blueTheme from "./themes/blueTheme";
import AppLocale from "../lngProvider";
import asyncComponent from "util/asyncComponent";
import {
  BLUE
} from "constants/ThemeColors";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import EmailVerification from './EmailVerification';
import { setInitUrl } from "../store/actions/Auth";
import RTL from "util/RTL";
import { setThemeColor } from "../store/actions/Setting";
import AppLayout from "./AppLayout";
import OnBoard from '../app/routes/onBoard'
// import DashBoard from "../app/routes/DashBoard";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />}
  />;

const App = (props) => {
  const dispatch = useDispatch();
  const { themeColor, locale } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const { match, location } = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }

  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);

  // if (location.pathname === '/') {
  //   if (authUser === null) {
  //     return (<Redirect to={'/signin'} />);
  //   } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
  //     return (<Redirect to={'/sample-page'} />);
  //   } else {
  //     return (<Redirect to={initURL} />);
  //   }
  // }
  if (location.pathname === '/') {
    if (authUser === null) {
      return ( <Redirect to={'/signin'}/> );
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      return ( <Redirect to={'app/conversations'}/> );
    } else {
      return ( <Redirect to={initURL}/> );
    }
  }
  const body = document.body.classList;
  body.remove(themeColor);
  body.remove('dark-theme');
  body.add(BLUE);
  let applyTheme = createMuiTheme(blueTheme);

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <RTL>
            <div className="app-main">
              <Switch>
              <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                  component={AppLayout} />
                <Route path='/signin' component={SignIn} />
                <Route path='/signup' component={SignUp} />
                <Route path={`/email-verify`} component={EmailVerification} />
                <RestrictedRoute path={`/onBoard`} component={OnBoard} authUser={authUser} />
                {/* <RestrictedRoute path={`/dashBoard`} component={DashBoard} authUser={authUser} /> */}
                <Route
                  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))} />
                {/* <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                  component={AppLayout} /> */}
                {/* <RestrictedRoute path={`/sample-page`} authUser={authUser}
                  component={asyncComponent(() => import('app/routes/SamplePage'))} />
                <RestrictedRoute path={`/conversations`} authUser={authUser}
                  component={asyncComponent(() => import('app/routes/chatPanel/basic'))} />
                <Route path={`/support`}
                  component={asyncComponent(() => import('app/routes/Support'))} />
                <RestrictedRoute path={`/voice-mail`} authUser={authUser}
                 component={asyncComponent(() => import('app/routes/voiceMails'))} />
                <Route path='/signin' component={SignIn} />
                <Route path='/signup' component={SignUp} />
                <Route path={`/email-verify`} component={EmailVerification} />
                <Route
                  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))} /> */}
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};


export default App;
