import axios from '../../util/axios';
import { createUrl } from '../../util/common';
import * as urls from 'util/urlsConst';

export function fetchBotDetails(teamBotId) {
    return axios.request({
        method: 'get',
        url: createUrl(urls.USER + urls.TEAMBOT_DETAILS) + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

  export function getAllBots() {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'get',
        url: createUrl(urls.TEAMBOT + urls.ALL_TEAM_BOTS),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
}


export function getAllIntents(teamBotId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'get',
        url: createUrl(urls.TEAMBOT + urls.ALL_INTENTS) + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
}
export function getAllEntities(teamBotId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'get',
        url: createUrl(urls.TEAMBOT + urls.ALL_ENTITIES) + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
}

export function getIntent(intentId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'get',
        url: createUrl(urls.TEAMBOT + urls.GET_INTENT) + `/${intentId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
}

export function getEntities(teamBotId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'get',
        url: createUrl(urls.TEAMBOT + urls.ALL_ENTITIES) + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
}

export function getEntity(entityId, teamBotId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'get',
        url: createUrl(urls.TEAMBOT + urls.GET_ENTITY) + `/${entityId}` + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
}

export function updateSynonyms(data, entityEntryId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'post',
        url: createUrl(urls.TEAMBOT + urls.ADD_SYNONYMS) + `/${entityEntryId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
        data: data
    })
}


export function addTrainingPhrases(data, intentId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'post',
        url: createUrl(urls.AGENT + urls.CREATE_TRAINING_PHRASE) + `/${intentId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
        data: data
    })
}

export function deployBot(teamBotId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'post',
        url: createUrl(urls.TEAMBOT + urls.DEPLOY_TEAMBOT) + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
}

export function updateAction(data, intentId, actionId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'post',
        url: createUrl(urls.TEAMBOT + urls.INTENT) + `/${intentId}` + '/action' + `/${actionId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
        data: data
    })
}

export function getTags(teamBotId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'get',
        url: createUrl(urls.TAG + urls.GET_TAGS) + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
}

export function getAllMessages(teamBotId, params) {
    return axios.request({
        method: 'get',
        url: createUrl(urls.TEAMBOT + urls.GET_TEAMBOT_MESSAGES + '/' + teamBotId),
        headers: {
            'Content-Type': 'application/json'
        },
        params: params
    })
}

export function handleMessage(messageId, data) {
    return axios.request({
        method: 'post',
        url: createUrl(urls.AGENT + urls.ADD_TRAINING_PHRASE_RASA + '/' + messageId),
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function createEntityEntry(data, entityId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'post',
        url: createUrl(urls.AGENT + urls.CREATE_ENTITY_ENTRY) + `/${entityId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
        data: data
    })
}

export function createTag(data, teamBotId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'post',
        url: createUrl(urls.TAG + urls.CREATE_TAG) + `/${teamBotId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
        data: data
    })
}

export function editTag(data, tagId) {
    const access_token = localStorage.getItem('token')
    return axios.request({
        method: 'post',
        url: createUrl(urls.TAG + urls.EDIT_TAG) + `/${tagId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
        data: data
    })
}