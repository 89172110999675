import axios from '../../util/axios';
import {createUrl} from '../../util/common';
import * as urls from '../../util/urlsConst';

export function createTeamAndTeambot(params){
    const access_token = localStorage.getItem('token')
    return axios.request({
      method: 'post',
      url: createUrl(urls.TEAM + urls.CREATE_TEAM_AND_TEAMBOT),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      data: params
    })
  }

export function getTeam(){
    const access_token = localStorage.getItem('token')
    return axios.request({
      method: 'get',
      url: createUrl(urls.USER + urls.TEAMBOT),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      }
    })
  }