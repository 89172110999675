import React, {useState, useEffect} from 'react'
import AboutEmitrrServices from './aboutEmitrr'
import CreateTeam from './createTeam'
import CallForward from './setupInstructions'
import styles from './index.module.css'
import {useDispatch} from 'react-redux'
import actions from 'store/actions/onBoard'

function OnBoard(props) {
    const dispatch = useDispatch()
    const [screen, setScreen] = useState(1);

    const handleSetScreen = (value) => {
        setScreen(value)
    }
    useEffect(() => {
        dispatch({type: actions.CHECK_TEAMBOT_EXISTS})
      }, [dispatch]);

    switch(screen){
        case 1:
            return (
                <div className={styles.onBoard}>
                    <h1 className={styles.onBoardTitle}>Welcome to Emitrr <span role="img" aria-label="Hand">👋</span></h1>
                    {/* <h1>Welcome to Emitrr 👋 */}
                    <AboutEmitrrServices className={styles.onBoardContainer}  handleSetScreen={handleSetScreen} />
                </div>
            )
        case 2:
            return (
                <div className={styles.onBoard}>
                    <h1 className={styles.onBoardTitle}>Step 1 - Choose your Business Industry</h1>
                    <CreateTeam  className={styles.onBoardContainer} handleSetScreen={handleSetScreen} />
                </div>
            )
        case 3:
            return (
                <div className={styles.onBoard}>
                    <h1 className={styles.onBoardTitle}>Step 2 - Setup Conditional Call Forwarding</h1>
                    <CallForward  className={styles.onBoardContainer} handleSetScreen={handleSetScreen} />
                </div>
            )
        default:
            return null
    }
}

export default  OnBoard;