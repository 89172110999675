export const USER = '/customer';
export const LOGIN = '/dashboardLogin';
export const REGISTER = '/register';
export const RESEND ='/resend';
export const TEAMBOT = '/teambot';
export const TEAMBOT_DETAILS = '/teambotDetails';
export const CONVERSATION = '/conversation';
export const FETCH_CONVERSATION = '/fetchConversations';
export const MESSAGES = '/messages';
export const MESSAGE_DETAILS = '/messageDetails';
export const VOICEMAIL = '/voicemail';
export const SEND_MSG = '/sendMessage';
export const SETTING = '/setting';
export const TEAM = '/team'
export const CREATE_TEAM_AND_TEAMBOT = '/createTeamAndTeambot'
export const TEMPLATE = '/template'
export const GET_TEMPLATES = '/getTemplates'
export const TOGGLE_STATUS = '/toggleStatus'
export const RESET_PASSWORD = '/reset-password'
export const EDIT_NAME = '/editName'
export const CREATE_NOTE = '/createNote'
export const ALL_TEAM_BOTS = '/allTeamBots'
export const ALL_INTENTS = '/allIntents'
export const AGENT = '/agent'
export const DEPLOY_TEAMBOT = '/deployTeamBot'
export const CREATE_TRAINING_PHRASE = '/createTrainingPhrase'
export const GET_INTENT = '/getIntent'
export const ALL_ENTITIES = '/allEntities'
export const GET_ENTITY = '/getEntity'
export const ADD_SYNONYMS = '/addSynonyms'
export const INTENT = '/intent'
export const ACTION = '/action'
export const TAG = '/tag'
export const GET_TAGS = '/get-tags'
export const GET_TEAMBOT_MESSAGES = '/get-teambot-messages'
export const ADD_TRAINING_PHRASE_RASA = '/add-training-phrase-rasa'
export const CREATE_ENTITY_ENTRY = '/create-entity-entry'
export const CREATE_TAG = '/create-tag'
export const EDIT_TAG = '/edit-tag'